import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { mdiTextBox } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

export default function ViewerImprintDropdownMenuItem(): JSX.Element {
  const { t } = useTranslation();
  return (
    <DropdownMenuItem>
      <div className="flex gap-4">
        <Icon path={mdiTextBox} size={1} />
        <a
          href={t("ViewerImprintDropdownMenuItem.href")}
          target="_blank"
          className="flex items-center"
        >
          {t("ViewerImprintDropdownMenuItem.label")}
        </a>
      </div>
    </DropdownMenuItem>
  );
}
