export enum RegionTool {
  Transform = "transform",
  Vertices = "vertices",
  Rectangle = "rectangle",
  Polygon = "polygon",
}

export type SwitchRegionToolEditorAction = {
  type: "switchTool";
  tool: RegionTool;
};

export type SelectRegionEditorAction = {
  type: "selectRegion";
  regionId: string;
};

export type DeselectRegionEditorAction = {
  type: "deselectRegion";
};

export type EditorAction =
  | SwitchRegionToolEditorAction
  | SelectRegionEditorAction
  | DeselectRegionEditorAction;

export type EditorState = {
  tool: RegionTool;
  selectedRegionId?: string;
};

export function editorReducer(
  state: EditorState,
  action: EditorAction,
): EditorState {
  if (action.type === "switchTool") {
    return {
      ...state,
      tool: action.tool,
    };
  }

  if (action.type === "selectRegion") {
    return {
      ...state,
      selectedRegionId: action.regionId,
    };
  }

  if (action.type === "deselectRegion") {
    return {
      ...state,
      selectedRegionId: undefined,
    };
  }

  return state;
}
