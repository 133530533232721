import { PagesOverviewSidebarTabContentFragment } from "../../client/generated";
import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";
import PageThumbnail from "./PageThumbnail";

type Props = {
  fragment: PagesOverviewSidebarTabContentFragment;
};

export default function PagesOverviewSidebarTabContent({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <SidebarTabContent
      value="pages-overview-tab"
      title={t("PagesOverviewSidebarTabContent.title")}
    >
      <div className="flex flex-col gap-4 p-4">
        {fragment.pages.map((page) => (
          <PageThumbnail key={page.id} fragment={page} />
        ))}
      </div>
    </SidebarTabContent>
  );
}
