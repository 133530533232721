import { Badge } from "@acdc2/ui/components/ui/badge";
import { PageThumbnailFragment } from "../../client/generated";
import { routingPaths } from "../../navigation/constants";
import { useProtectedImageSource } from "../../client/useProtectedImage";
import { AccessLoading } from "@acdc2/ui/components/ui/access-loading";
import { useNavigateTransition } from "../../navigation/useNavigateTransition";

type Props = {
  fragment: PageThumbnailFragment;
};

export default function PageThumbnail({ fragment }: Props): JSX.Element {
  const [isPending, navigate] = useNavigateTransition();
  const src = useProtectedImageSource(fragment.pngUrl);

  return (
    <div
      style={{ aspectRatio: `${fragment.width}/${fragment.height}` }}
      className="relative border w-full cursor-pointer"
      onClick={() =>
        navigate(routingPaths.documentPage(fragment.document.id, fragment.id))
      }
    >
      {src ? (
        <img className="absolute bg-white w-full h-full" src={src} />
      ) : (
        <div className="absolute w-full h-full bg-slate-200 dark:bg-slate-800 animate-pulse"></div>
      )}
      <div className="p-4 absolute bottom-0 right-0">
        <Badge>{fragment.index}</Badge>
      </div>
      {isPending && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <AccessLoading />
        </div>
      )}
    </div>
  );
}
