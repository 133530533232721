import ViewerHelpDropdownMenuItem from "./ViewerHelpDropdownMenuItem";
import ViewerLoginDropdownMenuItem from "./ViewerLoginDropdownMenuItem";
import ViewerLogoutDropdownMenuItem from "./ViewerLogoutDropdownMenuItem";
import ViewerSettingsDropdownMenuItem from "./ViewerSettingsDropdownMenuItem";
import SidebarButtonIcon from "./SidebarButtonIcon";
import SidebarButton from "./SidebarButton";
import { mdiAccountCircle } from "@mdi/js";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@acdc2/ui/components/ui/dropdown-menu";
import ViewerImprintDropdownMenuItem from "./ViewerImprintDropdownMenuItem";
import ViewerPrivacyDropdownMenuItem from "./ViewerPrivacyDropdownMenuItem";
import ViewerVersionDropdownItem from "./ViewerVersionDropdownItem";

export default function ViewerAccountButton(): JSX.Element {
  const { t } = useTranslation();
  const auth = useAuth();
  const username = auth.user?.profile?.preferred_username;

  return (
    <DropdownMenu>
      <SidebarButton tooltip={t("ViewerAccountButton.tooltip")}>
        <DropdownMenuTrigger asChild>
          <SidebarButtonIcon path={mdiAccountCircle} />
        </DropdownMenuTrigger>
      </SidebarButton>
      <DropdownMenuContent className="my-2" side="right">
        {username ? (
          <>
            <DropdownMenuLabel>
              {t("ViewerAccountButton.label", { name: username })}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <ViewerHelpDropdownMenuItem />
            <ViewerImprintDropdownMenuItem />
            <ViewerPrivacyDropdownMenuItem />
            <ViewerVersionDropdownItem />
            <DropdownMenuSeparator />
            <ViewerSettingsDropdownMenuItem />
            <ViewerLogoutDropdownMenuItem />
          </>
        ) : (
          <>
            <ViewerHelpDropdownMenuItem />
            <ViewerImprintDropdownMenuItem />
            <ViewerPrivacyDropdownMenuItem />
            <ViewerVersionDropdownItem />
            <ViewerLoginDropdownMenuItem />
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
