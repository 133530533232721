import { SVGProps } from "react";
import { PersistedRegionFragment } from "../../client/generated";

type Props = SVGProps<SVGPolygonElement> & {
  fragment: PersistedRegionFragment;
};

export default function PersistedRegion({
  fragment,
  fill,
  stroke,
  ...rest
}: Props) {
  return (
    <svg viewBox="0 0 1 1" preserveAspectRatio="none">
      <polygon
        points={fragment.shape.vertices
          .map((vertex) => `${vertex.left},${vertex.top}`)
          .join(" ")}
        fill={fill ?? "transparent"}
        stroke={stroke ?? "pink"}
        vectorEffect="non-scaling-stroke"
        {...rest}
      />
    </svg>
  );
}
