import { useDocumentEditorScreenSuspenseQuery } from "../client/generated";
import { useParams } from "react-router-dom";
import Sidebar from "../shell/Sidebar";
import Scaffold from "../shell/Scaffold";
import MetadataSidebarTabButton from "../editors/metadata/MetadataSidebarTabButton";
import MetadataSidebarTabContent from "../editors/metadata/MetadataSidebarTabContent";
import PageThumbnail from "../editors/pages/PageThumbnail";
import OverviewSidebarButton from "../editors/OverviewSidebarButton";
import PagesOverviewSidebarTabButton from "../editors/pages/PagesOverviewSidebarTabButton";
import RegionsEditorSidebarTabButton from "../editors/regions/RegionsEditorSidebarTabButton";
import TableEditorSidebarTabButton from "../editors/table/TableEditorSidebarTabButton";
import ListEditorSidebarTabButton from "../editors/list/ListEditorSidebarTabButton";
import ImageEditorSidebarTabButton from "../editors/image/ImageEditorSidebarTabButton";
import MathEditorSidebarTabButton from "../editors/math/MathEditorSidebarTabButton";
import ChemistryEditorSidebarTabButton from "../editors/chemistry/ChemistryEditorSidebarTabButton";
import ReadingOrderSidebarTabButton from "../editors/reading-order/ReadingOrderSidebarTabButton";
import SidebarDivider from "../shell/SidebarDivider";
import PreviousPageSidebarButton from "../editors/PreviousPageSidebarButton";
import NextPageSidebarButton from "../editors/NextPageSidebarButton";

export default function DocumentEditorScreen(): JSX.Element {
  const { documentId } = useParams<{ documentId: string }>();
  const { data } = useDocumentEditorScreenSuspenseQuery({
    variables: { documentId: documentId! },
  });

  if (data.node?.__typename !== "Document") {
    throw TypeError("Document not found");
  }

  return (
    <Scaffold
      sidebar={
        <Sidebar
          defaultTabValue="metadata-editor-tab"
          buttons={
            <>
              <OverviewSidebarButton fragment={data.node} disabled />
              <PreviousPageSidebarButton />
              <NextPageSidebarButton />
              <SidebarDivider />
              <MetadataSidebarTabButton />
              <PagesOverviewSidebarTabButton disabled />
              <RegionsEditorSidebarTabButton disabled />
              <TableEditorSidebarTabButton disabled />
              <ListEditorSidebarTabButton disabled />
              <ImageEditorSidebarTabButton disabled />
              <MathEditorSidebarTabButton disabled />
              <ChemistryEditorSidebarTabButton disabled />
              <ReadingOrderSidebarTabButton disabled />
            </>
          }
          content={
            <>
              <MetadataSidebarTabContent fragment={data.node} />
            </>
          }
        />
      }
    >
      <div className="p-4 flex flex-col gap-4">
        <h1 className="text-lg">{data.node.name}</h1>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {data.node.pages.pages.map((page) => (
            <PageThumbnail key={page.id} fragment={page} />
          ))}
        </div>
      </div>
    </Scaffold>
  );
}
