import { DropdownMenuItem } from "@acdc2/ui/components/ui/dropdown-menu";
import { mdiGit } from "@mdi/js";
import Icon from "@mdi/react";

export default function ViewerVersionDropdownItem(): JSX.Element {
  return (
    <DropdownMenuItem>
      <div className="flex gap-4">
        <Icon path={mdiGit} size={1} />
        <p className="flex items-center">
          {import.meta.env.VITE_ACDC2_VCS_REF}
        </p>
      </div>
    </DropdownMenuItem>
  );
}
