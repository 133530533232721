import { Icon } from "@mdi/react";
import {
  mdiCursorDefault,
  mdiShapePolygonPlus,
  mdiShapeRectanglePlus,
  mdiVectorSquareEdit,
} from "@mdi/js";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@acdc2/ui/components/ui/toggle-group";
import { useTranslation } from "react-i18next";
import { EditorAction, EditorState, RegionTool } from "../reducers";
import { Dispatch } from "react";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function RegionsToolbox({
  state,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const onValueChange = (tool: RegionTool) => {
    dispatch({ type: "switchTool", tool });
  };

  return (
    <ToggleGroup type="single" value={state.tool} onValueChange={onValueChange}>
      <ToggleGroupItem
        value={RegionTool.Transform}
        aria-label={t("RegionsToolbox.transform.label")}
      >
        <Icon path={mdiCursorDefault} className="w-4 h-4" />
      </ToggleGroupItem>
      <ToggleGroupItem
        value={RegionTool.Vertices}
        aria-label={t("RegionsToolbox.vertices.label")}
      >
        <Icon path={mdiVectorSquareEdit} className="w-4 h-4" />
      </ToggleGroupItem>
      <ToggleGroupItem
        value={RegionTool.Rectangle}
        aria-label={t("RegionsToolbox.rectangle.label")}
      >
        <Icon path={mdiShapeRectanglePlus} className="w-4 h-4" />
      </ToggleGroupItem>
      <ToggleGroupItem
        value={RegionTool.Polygon}
        aria-label={t("RegionsToolbox.polygon.label")}
      >
        <Icon path={mdiShapePolygonPlus} className="w-4 h-4" />
      </ToggleGroupItem>
    </ToggleGroup>
  );
}
