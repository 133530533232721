import { Dispatch, useCallback, useEffect, useState } from "react";
import {
  Aabb,
  TransformableRegionFragment,
  useDeleteRegionMutation,
  useUpdateRegionShapeMutation,
} from "../../client/generated";
import { EditorAction } from "../reducers";
import { usePageScale } from "../hooks";
import PersistedRegion from "./PersistedRegion";
import TransformableRegionContextMenu from "./TransformableRegionContextMenu";
import { toPercent } from "../utils";
import RegionTransformHandle from "./RegionTransformHandle";

type Props = {
  fragment: TransformableRegionFragment;
  svgWidth: number;
  svgHeight: number;
  isSelected: boolean;
  dispatch: Dispatch<EditorAction>;
};

type Point = {
  x: number;
  y: number;
};

export default function TransformableRegion({
  fragment,
  svgWidth,
  svgHeight,
  isSelected,
  dispatch,
}: Props) {
  const pageScale = usePageScale();
  const [isDragged, setIsDragged] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<Point>();

  const [deltaAABB, setDeltaAABB] = useState<Aabb>({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
  });

  const scaleX = deltaAABB.width / fragment.shape.aabb.width + 1;
  const scaleY = deltaAABB.height / fragment.shape.aabb.height + 1;
  const deltaX = deltaAABB.left * svgWidth;
  const deltaY = deltaAABB.top * svgHeight;

  const [updateRegionShape] = useUpdateRegionShapeMutation();
  const [deleteRegion] = useDeleteRegionMutation({
    variables: { regionId: fragment.id },
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onUpdate = useCallback(
    async (delta: Aabb) => {
      function transformX(x: number): number {
        const transformedX =
          fragment.shape.aabb.left +
          delta.left +
          (x - fragment.shape.aabb.left) * scaleX;
        return transformedX;
      }

      function transformY(y: number): number {
        const transformedY =
          fragment.shape.aabb.top +
          delta.top +
          (y - fragment.shape.aabb.top) * scaleY;
        return transformedY;
      }

      const { errors, data } = await updateRegionShape({
        variables: {
          regionId: fragment.id,
          vertices: fragment.shape.vertices.map((vertex) => ({
            left: transformX(vertex.left),
            top: transformY(vertex.top),
          })),
        },
      });

      if (errors) {
        console.error("Error moving region:", errors);
      } else if (data?.updateRegionShape.userErrors) {
        console.error(
          "User error moving region:",
          data.updateRegionShape.userErrors,
        );
      } else {
        setDeltaAABB({ height: 0, left: 0, top: 0, width: 0 });
      }
    },
    [scaleX, scaleY, fragment, updateRegionShape],
  );

  const onDelete = useCallback(async () => {
    const { errors, data } = await deleteRegion();

    if (errors) {
      console.error("Error deleting region:", errors);
    } else if (data?.deleteRegion.userErrors) {
      console.error(
        "User error deleting region:",
        data.deleteRegion.userErrors,
      );
    } else {
      dispatch({ type: "deselectRegion" });
    }
  }, [deleteRegion, dispatch]);

  const onMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch({ type: "selectRegion", regionId: fragment.id });

    if (event.buttons === 1) {
      setIsDragged(true);
    }
  };

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      setDeltaAABB((oldDeltaAABB) => ({
        ...oldDeltaAABB,
        top: oldDeltaAABB.top + event.movementY / svgHeight / pageScale,
        left: oldDeltaAABB.left + event.movementX / svgWidth / pageScale,
      }));
    },
    [svgHeight, svgWidth, pageScale],
  );

  const onContextMenu = (event: React.MouseEvent<SVGPolygonElement>) => {
    if (!isSelected) return;
    event.preventDefault();
    setContextMenuPosition({ x: event.pageX, y: event.pageY });
  };

  useEffect(() => {
    if (!isDragged) return;

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [isDragged, onMouseMove]);

  useEffect(() => {
    if (!isDragged) return;

    const onMouseUp = () => {
      setIsDragged(false);
      onUpdate(deltaAABB);
    };

    document.addEventListener("mouseup", onMouseUp);

    return () => {
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragged, deltaAABB, onUpdate]);

  useEffect(() => {
    if (!isSelected) return;

    const onKeyDown = async (event: KeyboardEvent) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        onDelete();
      } else if (event.key === "ArrowLeft") {
        onUpdate({
          ...deltaAABB,
          left: deltaAABB.left - 0.001,
        });
      } else if (event.key === "ArrowRight") {
        onUpdate({
          ...deltaAABB,
          left: deltaAABB.left + 0.001,
        });
      } else if (event.key === "ArrowUp") {
        onUpdate({
          ...deltaAABB,
          top: deltaAABB.top - 0.001,
        });
      } else if (event.key === "ArrowDown") {
        onUpdate({
          ...deltaAABB,
          top: deltaAABB.top + 0.001,
        });
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isSelected, deltaAABB, onDelete, onUpdate]);

  return (
    <>
      <g
        transform={`translate(${deltaX}, ${deltaY}) scale(${scaleX}, ${scaleY})`}
        style={{ transformBox: "fill-box" }}
      >
        <PersistedRegion
          fragment={fragment}
          onMouseDown={onMouseDown}
          onContextMenu={onContextMenu}
        />
      </g>

      {isSelected && (
        <>
          <rect
            x={toPercent(
              Math.min(
                fragment.shape.aabb.left + deltaAABB.left,
                fragment.shape.aabb.left +
                  deltaAABB.left +
                  fragment.shape.aabb.width +
                  deltaAABB.width,
              ),
            )}
            y={toPercent(
              Math.min(
                fragment.shape.aabb.top + deltaAABB.top,
                fragment.shape.aabb.top +
                  deltaAABB.top +
                  fragment.shape.aabb.height +
                  deltaAABB.height,
              ),
            )}
            width={toPercent(
              Math.abs(fragment.shape.aabb.width + deltaAABB.width),
            )}
            height={toPercent(
              Math.abs(fragment.shape.aabb.height + deltaAABB.height),
            )}
            fill="transparent"
            stroke="green"
            strokeDasharray="4"
            className="pointer-events-none"
          />

          <RegionTransformHandle
            position={
              fragment.shape.aabb.height + deltaAABB.height < 0
                ? fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "bottomRight"
                  : "bottomLeft"
                : fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "topRight"
                  : "topLeft"
            }
            cx={(fragment.shape.aabb.left + deltaAABB.left) * svgWidth}
            cy={(fragment.shape.aabb.top + deltaAABB.top) * svgHeight}
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                top: oldDeltaAABB.top + movementY / svgHeight / pageScale,
                left: oldDeltaAABB.left + movementX / svgWidth / pageScale,
                width: oldDeltaAABB.width - movementX / svgWidth / pageScale,
                height: oldDeltaAABB.height - movementY / svgHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            position={
              fragment.shape.aabb.height + deltaAABB.height < 0
                ? "bottom"
                : "top"
            }
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                (fragment.shape.aabb.width + deltaAABB.width) / 2) *
              svgWidth
            }
            cy={(fragment.shape.aabb.top + deltaAABB.top) * svgHeight}
            onDrag={(_, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                top: oldDeltaAABB.top + movementY / svgHeight / pageScale,
                height: oldDeltaAABB.height - movementY / svgHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            position={
              fragment.shape.aabb.height + deltaAABB.height < 0
                ? fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "bottomLeft"
                  : "bottomRight"
                : fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "topLeft"
                  : "topRight"
            }
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                fragment.shape.aabb.width +
                deltaAABB.width) *
              svgWidth
            }
            cy={(fragment.shape.aabb.top + deltaAABB.top) * svgHeight}
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                top: oldDeltaAABB.top + movementY / svgHeight / pageScale,
                width: oldDeltaAABB.width + movementX / svgWidth / pageScale,
                height: oldDeltaAABB.height - movementY / svgHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />

          <RegionTransformHandle
            position={
              fragment.shape.aabb.width + deltaAABB.width < 0 ? "right" : "left"
            }
            cx={(fragment.shape.aabb.left + deltaAABB.left) * svgWidth}
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                (fragment.shape.aabb.height + deltaAABB.height) / 2) *
              svgHeight
            }
            onDrag={(movementX) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                left: oldDeltaAABB.left + movementX / svgWidth / pageScale,
                width: oldDeltaAABB.width - movementX / svgWidth / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            position={
              fragment.shape.aabb.width + deltaAABB.width < 0 ? "left" : "right"
            }
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                fragment.shape.aabb.width +
                deltaAABB.width) *
              svgWidth
            }
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                (fragment.shape.aabb.height + deltaAABB.height) / 2) *
              svgHeight
            }
            onDrag={(movementX) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                width: oldDeltaAABB.width + movementX / svgWidth / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />

          <RegionTransformHandle
            position={
              fragment.shape.aabb.height + deltaAABB.height < 0
                ? fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "topRight"
                  : "topLeft"
                : fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "bottomRight"
                  : "bottomLeft"
            }
            cx={(fragment.shape.aabb.left + deltaAABB.left) * svgWidth}
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                fragment.shape.aabb.height +
                deltaAABB.height) *
              svgHeight
            }
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                left: oldDeltaAABB.left + movementX / svgWidth / pageScale,
                width: oldDeltaAABB.width - movementX / svgWidth / pageScale,
                height: oldDeltaAABB.height + movementY / svgHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            position={
              fragment.shape.aabb.height + deltaAABB.height < 0
                ? "top"
                : "bottom"
            }
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                (fragment.shape.aabb.width + deltaAABB.width) / 2) *
              svgWidth
            }
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                fragment.shape.aabb.height +
                deltaAABB.height) *
              svgHeight
            }
            onDrag={(_, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                height: oldDeltaAABB.height + movementY / svgHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
          <RegionTransformHandle
            position={
              fragment.shape.aabb.height + deltaAABB.height < 0
                ? fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "topLeft"
                  : "topRight"
                : fragment.shape.aabb.width + deltaAABB.width < 0
                  ? "bottomLeft"
                  : "bottomRight"
            }
            cx={
              (fragment.shape.aabb.left +
                deltaAABB.left +
                fragment.shape.aabb.width +
                deltaAABB.width) *
              svgWidth
            }
            cy={
              (fragment.shape.aabb.top +
                deltaAABB.top +
                fragment.shape.aabb.height +
                deltaAABB.height) *
              svgHeight
            }
            onDrag={(movementX, movementY) => {
              setDeltaAABB((oldDeltaAABB) => ({
                ...oldDeltaAABB,
                width: oldDeltaAABB.width + movementX / svgWidth / pageScale,
                height: oldDeltaAABB.height + movementY / svgHeight / pageScale,
              }));
            }}
            onDone={() => onUpdate(deltaAABB)}
          />
        </>
      )}
      {contextMenuPosition && (
        <TransformableRegionContextMenu
          position={contextMenuPosition}
          onDelete={onDelete}
          onClose={() => setContextMenuPosition(undefined)}
        />
      )}
    </>
  );
}
