import { useEffect, useState } from "react";

type Props = {
  cx: number;
  cy: number;
  position:
    | "topLeft"
    | "top"
    | "topRight"
    | "left"
    | "right"
    | "bottomLeft"
    | "bottom"
    | "bottomRight";
  onDrag: (movementX: number, movementY: number) => void;
  onDone: () => void;
};

export default function RegionTransformHandle({
  cx,
  cy,
  position,
  onDrag,
  onDone,
}: Props): JSX.Element {
  const [isDragged, setIsDragged] = useState(false);

  const onMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDragged(true);
  };

  useEffect(() => {
    if (!isDragged) return;

    const onMouseMove = (event: MouseEvent) => {
      if (event.buttons !== 1) {
        setIsDragged(false);
        return;
      }

      onDrag(event.movementX, event.movementY);
    };

    const onMouseUp = () => {
      setIsDragged(false);
      onDone();
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragged, onDrag, onDone]);

  const transformX = {
    topLeft: -17,
    top: -10,
    topRight: -3,
    left: -20,
    right: 0,
    bottomLeft: -17,
    bottom: -10,
    bottomRight: -3,
  }[position];

  const transformY = {
    topLeft: -17,
    top: -20,
    topRight: -17,
    left: -10,
    right: -10,
    bottomLeft: -3,
    bottom: 0,
    bottomRight: -3,
  }[position];

  const rotation = {
    topLeft: 45,
    top: 90,
    topRight: 135,
    left: 0,
    right: 0,
    bottomLeft: 135,
    bottom: 90,
    bottomRight: 45,
  }[position];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      x={cx + transformX}
      y={cy + transformY}
      onMouseDown={onMouseDown}
    >
      <g transform={`rotate(${rotation}, 10, 10)`}>
        <polygon points="5,5, 0,10 5,15" />
        <line x1="5" y1="10" x2="15" y2="10" stroke="black" strokeWidth="5" />
        <polygon points="15,5 20,10 15,15" />
      </g>
    </svg>
  );
}
