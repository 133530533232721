import { useRef, useState } from "react";
import {
  PageEditorScreenDocument,
  RectangleToolOverlayFragment,
  useCreateRegionMutation,
} from "../../client/generated";
import PersistedRegion from "./PersistedRegion";
import PageOverlay from "../PageOverlay";
import { toPercent } from "../utils";

type Props = {
  fragment: RectangleToolOverlayFragment;
};

type Point = {
  x: number;
  y: number;
};

export default function RectangleToolOverlay({ fragment }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [startCoords, setStartCoords] = useState<Point>();
  const [endCoords, setEndCoords] = useState<Point>();
  const [mutate] = useCreateRegionMutation({
    refetchQueries: [PageEditorScreenDocument],
  });

  const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!ref.current) return;
    setEndCoords(undefined);
    setStartCoords({
      x: event.nativeEvent.offsetX / ref.current.offsetWidth,
      y: event.nativeEvent.offsetY / ref.current.offsetHeight,
    });
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.buttons !== 1) return;
    if (!ref.current) return;
    setEndCoords({
      x: event.nativeEvent.offsetX / ref.current.offsetWidth,
      y: event.nativeEvent.offsetY / ref.current.offsetHeight,
    });
  };

  const onMouseUp = async () => {
    if (!startCoords || !endCoords) return;

    const { data, errors } = await mutate({
      variables: {
        pageId: fragment.id,
        vertices: [
          {
            left: startCoords.x,
            top: startCoords.y,
          },
          {
            left: endCoords.x,
            top: startCoords.y,
          },
          {
            left: endCoords.x,
            top: endCoords.y,
          },
          {
            left: startCoords.x,
            top: endCoords.y,
          },
        ],
      },
    });

    if (errors) {
      console.log("Error creating region", errors);
    } else if (data?.createRegion.userErrors) {
      console.log("User error creating region", data.createRegion.userErrors);
    }
  };

  return (
    <PageOverlay
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {fragment.regions.map((region) => (
        <PersistedRegion key={region.id} fragment={region} />
      ))}

      {startCoords && endCoords && (
        <rect
          x={toPercent(Math.min(startCoords.x, endCoords.x))}
          y={toPercent(Math.min(startCoords.y, endCoords.y))}
          width={toPercent(Math.abs(endCoords.x - startCoords.x))}
          height={toPercent(Math.abs(endCoords.y - startCoords.y))}
          stroke="red"
          fill="transparent"
        />
      )}
    </PageOverlay>
  );
}
