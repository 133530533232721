import { useTranslation } from "react-i18next";
import SidebarTabContent from "../../shell/SidebarTabContent";
import RegionsToolbox from "./RegionsToolbox";
import { EditorAction, EditorState } from "../reducers";
import { Dispatch } from "react";
import { Button } from "@acdc2/ui/components/ui/button";
import { Icon } from "@mdi/react";
import { mdiDelete } from "@mdi/js";
import { useDeleteRegionFromSidebarMutation } from "../../client/generated";

type Props = {
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function RegionsEditorSidebarTabContent({
  state,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [deleteRegion] = useDeleteRegionFromSidebarMutation({
    update: (cache, result) => {
      if (result.data?.deleteRegion.region) {
        const id = cache.identify(result.data.deleteRegion.region);
        cache.evict({ id });
        cache.gc();
      }
    },
  });

  const onDeleteSelectedRegion = async () => {
    if (!state.selectedRegionId) return;
    const { data, errors } = await deleteRegion({
      variables: {
        regionId: state.selectedRegionId,
      },
    });

    if (errors) {
      console.error("Error deleting region", errors);
    } else if (data?.deleteRegion.userErrors) {
      console.error("User error deleting region", data.deleteRegion.userErrors);
    } else {
      dispatch({ type: "deselectRegion" });
    }
  };

  return (
    <SidebarTabContent
      value="regions-editor-tab"
      title={t("RegionsEditorSidebarTabContent.title")}
    >
      <RegionsToolbox state={state} dispatch={dispatch} />
      <Button
        variant="secondary"
        disabled={!state.selectedRegionId}
        onClick={onDeleteSelectedRegion}
      >
        <Icon path={mdiDelete} size={1} /> Delete selected region
      </Button>
    </SidebarTabContent>
  );
}
