import { useTranslation } from "react-i18next";
import SidebarButton from "../shell/SidebarButton";
import SidebarButtonIcon from "../shell/SidebarButtonIcon";
import { mdiChevronLeft } from "@mdi/js";
import { PreviousPageSidebarButtonFragment } from "../client/generated";
import { routingPaths } from "../navigation/constants";
import { useNavigateTransition } from "../navigation/useNavigateTransition";

type Props = {
  fragment?: PreviousPageSidebarButtonFragment;
};

export default function PreviousPageSidebarButton({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isPending, navigate] = useNavigateTransition();

  const onClick = () => {
    if (fragment && fragment.previousPage) {
      navigate(
        routingPaths.documentPage(
          fragment.document.id,
          fragment.previousPage.id,
        ),
      );
    }
  };

  return (
    <SidebarButton
      tooltip={t("PageSidebarTabPreviousButton.tooltip")}
      loading={isPending}
    >
      <SidebarButtonIcon
        path={mdiChevronLeft}
        disabled={!fragment?.previousPage}
        onClick={onClick}
      />
    </SidebarButton>
  );
}
