import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

export function useProtectedImageSource(imagePath?: string | null) {
  const [src, setSrc] = useState<string>();
  const auth = useAuth();

  useEffect(() => {
    if (!imagePath) {
      return;
    }

    const url = `${import.meta.env.VITE_ACDC2_BASE_API_URL}/${imagePath}`;
    const headers = { Authorization: `Bearer ${auth.user?.access_token}` };

    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blob) => setSrc(URL.createObjectURL(blob)))
      .catch(console.error);
  }, [imagePath, auth.user?.access_token]);

  return src;
}
