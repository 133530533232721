import { forwardRef, HTMLAttributes } from "react";

type Props = HTMLAttributes<HTMLDivElement>;

const PageOverlay = forwardRef<HTMLDivElement, Props>(
  function (props, ref): JSX.Element {
    const { children, ...rest } = props;

    return (
      <div ref={ref} {...rest} className="absolute w-full h-full top-0 left-0">
        <svg className="w-full h-full">{children}</svg>
      </div>
    );
  },
);

export default PageOverlay;
