import { createPortal } from "react-dom";

type Props = {
  position: { x: number; y: number };
  onDelete: () => void;
  onClose: () => void;
};

export default function TransformableRegionContextMenu({
  position,
  onDelete,
  onClose,
}: Props): JSX.Element {
  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onClose();
  };

  return createPortal(
    <div
      className="fixed top-0 left-0 right-0 bottom-0 z-10"
      onClick={onClose}
      onContextMenu={onContextMenu}
    >
      <div style={{ left: position.x, top: position.y }} className="absolute">
        <div className="bg-slate-200" onClick={onDelete}>
          Delete
        </div>
      </div>
    </div>,
    document.body,
  );
}
