import { Dispatch, useRef } from "react";
import { TransformToolOverlayFragment } from "../../client/generated";
import { EditorAction, EditorState } from "../reducers";
import TransformableRegion from "./TransformableRegion";
import PageOverlay from "../PageOverlay";

type Props = {
  fragment: TransformToolOverlayFragment;
  state: EditorState;
  dispatch: Dispatch<EditorAction>;
};

export default function TransformToolOverlay({
  fragment,
  state,
  dispatch,
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  const onMouseDown = () => {
    dispatch({ type: "deselectRegion" });
  };

  return (
    <PageOverlay ref={ref} onMouseDown={onMouseDown}>
      {[...fragment.regions]
        .sort((a) => (a.id === state.selectedRegionId ? 1 : 0))
        .map((region) => (
          <TransformableRegion
            key={region.id}
            fragment={region}
            svgWidth={ref.current?.offsetWidth ?? 1}
            svgHeight={ref.current?.offsetHeight ?? 1}
            isSelected={state.selectedRegionId === region.id}
            dispatch={dispatch}
          />
        ))}
    </PageOverlay>
  );
}
