import { PageFragment } from "../client/generated";
import { useProtectedImageSource } from "../client/useProtectedImage";
import PageSkeleton from "./PageSkeleton";

type Props = {
  fragment: PageFragment;
};

export default function Page({ fragment }: Props): JSX.Element {
  const src = useProtectedImageSource(fragment.svgUrl);

  return (
    <div
      className="bg-white shadow"
      style={{ width: `${fragment.width}in`, height: `${fragment.height}in` }}
    >
      {src ? (
        <img src={src} className="h-full" />
      ) : (
        <PageSkeleton fragment={fragment} />
      )}
    </div>
  );
}
